import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from './modules/components/Markdown';
import Typography from './modules/components/Typography';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import withRoot from './modules/withRoot';
import terms from './modules/views/terms.md';

function Terms() {
  const [text, setText] = React.useState('')

  React.useEffect(() => {
      fetch(terms)
      .then((response) => response.text())
      .then((md) => {
          setText(md)
      })
  }, [])

  return (
    <div>
      <AppAppBar />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Terms
          </Typography>
          <Markdown>{text}</Markdown>
        </Box>
      </Container>
      <AppFooter />
    </div>
  );
}

export default Terms;
